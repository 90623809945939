<template lang="pug">
    main#PrecoDoDia
        .wrapper
            .header
                h4 Preço do dia 
                    SvgIcon(data="@svgs/wave.svg", original).wave
                .select
                    p Selecione o local
                    select(v-model="precoId")
                        option(:value="null" disabled) CAMPO MOURÃO
                        option(v-for="unidade in unidadesPreco", :value="unidade.codigoUnidade") {{unidade.nomeUnidade}}
            .container
                table
                    thead
                        tr    
                            th Produtos
                            th Desc. Complementar
                            th Data e Hora do Preço
                            th R$
                            th Unid. Padrão
                    tbody
                        tr(v-for="produto in preco.precos")
                            td {{ produto.nomeProduto }}
                            td {{ produto.descricaoComplementar }}
                            td {{ formatDate(produto.dataPreco, true) }}
                            td {{ valor(produto.valorPreco) }}
                            td {{ produto.codigoUnidadePadrao }}
</template>

<script>
import { components as allComponents } from '@/mixins/component'
import { props } from '@/mixins/component'

export default {
    name: "component-preco-do-dia",
    components: {
        ...allComponents,
    },
    props,
    data() {
        return {
            ativo: false,
            preco: {},
            unidadesPreco: [],
            tipoUnidade: {},
            precoId: null,
        }
    },
    methods: {
        getCampo() {
            this.$axios.get('coamo-api/campo-mourao')
            .then(response => {
                this.preco = response.data.preco
                this.previsao = response.data.clima
            })
        },
        valor(valor) {
            return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL'}).format(valor)
        },
        formatDate(date, full = false) {
            const objData = new Date(date)
            let formatted;
            if (full) {
                formatted = `${objData.getDate()}/${objData.getMonth() + 1}/${objData.getFullYear()} ${objData.getHours()}:${objData.getMinutes()}`
            } else {
                formatted = `${objData.getDate()}.${objData.getMonth() + 1}`
            }
            return formatted
        },
        getUnidadePreco() {
            this.$axios.get('coamo-api/PRECO')
            .then(response => {
                this.unidadesPreco = response.data
            })
        },
        getPreco() {
            this.$axios.get(`coamo-api/preco/${this.precoId}`)
            .then(response => {
                this.preco = response.data
            })
        },
    },
    created() {
        this.getCampo()
        this.getUnidadePreco()
    },
    watch: {
        precoId: function () {
            this.getPreco()
        }
    },
}
</script>

<style lang="stylus" scoped src="./PrecoDoDia.styl"></style>
